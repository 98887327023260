<template>
  <UiTourDriver ref="driver" :config :tour="TOURS_IDS.PROPERTY_MAP" :highlight :type="TOURS_TYPES.UPDATE" />
</template>

<script setup lang="ts">
import type { Config, DriveStep } from 'driver.js'
import type { UiTourDriver } from '#build/components'
import { TOURS_IDS, TOURS_TYPES } from '~/constants'

const driver = ref<InstanceType<typeof UiTourDriver> | null>(null)

const highlight = ref<DriveStep>({
  element: '#map-toggle',
  popover: {
    title: 'Map full view button',
    description: `Expand the map for a larger view and hide property cards for better focus. 
      Click the bar anytime to bring cards back.`,
    nextBtnText: "Okay, I'll check!",
    showButtons: ['next'],
    side: 'right',
    align: 'center',
    onNextClick: () => {
      if (driver.value?.driverObj) {
        driver.value.driverObj.destroy()
      }
    },
  },
})

const config: Config = {
  stagePadding: 0,
  stageRadius: 0,
}
</script>

<style scoped lang="scss"></style>
